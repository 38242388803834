<script setup>
import DateForm from '@components/DateForm.vue'
import Input from '@components/Input.vue'
import { computed, ref } from 'vue'
import { useFindStore } from '@stores/FindStore.js'

const findStore= useFindStore()

const props = defineProps([
  'errors', 'tfn-nullable', 'force-show', 'tfnNotFound',
])
</script>

<template>
  <div class="max-w-xl mx-auto flex flex-col space-y-8">
    <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-9">
      <div class="sm:col-span-3">
        <Input v-model="findStore.firstname" :errors="errors.firstname" type="text" name="firstname" label="First Name" placeholder="John">
        </Input>
        <small class="text-xs text-gray-600">
          Legal First Name
        </small>
      </div>
      <div class="sm:col-span-3">
        <Input v-model="findStore.middlename" :errors="errors.middlename" class="sm:col-span-3" type="text" name="middlename" label="Middle Name" placeholder="Albert">
        </Input>
        <small class="text-xs text-gray-600">
          Legal Middle Name
        </small>
      </div>
      <div class="sm:col-span-3">
        <Input v-model="findStore.lastname" required :errors="errors.lastname" class="sm:col-span-3" type="text" name="lastname" label="Last Name" placeholder="Doe">
        </Input>
        <small class="text-xs text-gray-600">
          Legal Last Name
        </small>
      </div>
    </div>

    <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
      <Input v-model="findStore.email" required :errors="errors.email"
        class="sm:col-span-1" type="email" name="email" label="Email"
        placeholder="you@example.com"></Input>
      <Input v-model="findStore.confirmationemail" required
      :errors="errors.confirmationemail" class="sm:col-span-1" type="email"
      name="confirmationemail" label="Confirmation Email"
      placeholder="you@example.com"></Input> </div>

      <Input v-model="findStore.phone" required :errors="errors.phone" type="tel" name="phone" label="Mobile Phone Number" placeholder="0412345678"></Input>

      <DateForm
        :key="findStore.birthdate"
        v-model="findStore.birthdate"
        @update:date="(date) => findStore.birthdate = date"
        required
        name="birthdate"
        id="birthdate"
        label="Date of birth:"
        :errors="errors.birthdate"
      ></DateForm>

    <div>

      <div v-if="props.tfnNullable" class="flex flex-col space-y-8">
        <div>
          <label class="text-base font-semibold text-gray-900">
            Tax File Number (TFN), not mandatory but  strongly recommended:
          </label>
          <fieldset class="mt-4">
            <legend class="sr-only">
              Tax File Number (TFN), not mandatory but  strongly recommended:
            </legend>
            <div class="space-y-4 flex flex-col items-start sm:space-y-0">
              <div class="flex items-center">
                <input v-model="findStore.tfnNow" id="tfnNow" name="tfnNow" :value="true" type="radio"  class="h-4 w-4 border-gray-300 text-stone-600 focus:ring-stone-600">
                <label for="tfnNow" class="ml-3 block text-sm font-medium leading-6 text-gray-900">
                  I can supply my TFN now
                </label>
              </div>
              <div class="flex items-center">
                <input v-model="findStore.tfnNow" id="tfnLater" name="tfnNow" :value="false" type="radio"  class="h-4 w-4 border-gray-300 text-stone-600 focus:ring-stone-600">
                <label for="tfnLater" class="ml-3 block text-sm font-medium leading-6 text-gray-900">
                  I will supply my TFN later
                </label>
              </div>
            </div>
          </fieldset>
        </div>
        <Transition>
        <Input v-if="findStore.tfnNow" v-model="findStore.tfn"
        :errors="errors.tfn" type="text" name="tfn" label="Tax File Number (123 456 789) (optional)"/>
        </Transition>
      </div>

      <Input v-else-if="props.tfnNotFound || forceShow" v-model="findStore.tfn" required
      :errors="errors.tfn" type="text" name="tfn" label="Tax File Number (123 456 789)"/>
      <div v-if="(findStore.tfnNow && props.tfnNullable) || props.tfnNotFound || forceShow">
        <Transition>
          <div>
            <small id="tax-file-number_help_bn_register" class="text-xs text-gray-600">
              The TFN itself has 9 digits, with a check digit. Individuals received a 9-digit
              TFN. Your Tax File Number (TFN) can be found on any official TAX Office
              documents. We may contact you to provide your TFN if the ATO cannot verify your
              information with the details you have provided, as a TFN number is required for
              an ABN number to be issued.
            </small>
            <blockquote class="p-4 my-4 bg-gray-100 border-l-4 border-gray-400 space-y-2">
              <p class="text-lg text-gray-900 font-bold">Can't find your TFN?</p>
              <p class="text-sm text-gray-700">> Look at your income tax notice of assessment.</p>
              <p class="text-sm text-gray-700">> Look at a payment summary (provided by your employer) or your super statement.</p>
              <p class="text-sm text-gray-700">> If you have a myGov account linked to the ATO, you can access your TFN online.</p>
            </blockquote>
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>
